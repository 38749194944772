/// PLV-4265 Criação completo do estilo TOOLTIP 
.tooltipInfo {
    display: inline;
    vertical-align: middle;
    font-size: 15px;
    align-items: center;
    padding: 0px 0px 3px 5px;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #002541;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  box-shadow: 2px 2px 5px rgba(0,0,0,.2);
  outline: none;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -120px;

  opacity: 0;
  transition: opacity 0.3s;
}
  
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
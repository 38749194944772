$mobile: 360px;
$desk: 768px;
$fullhd: 1900px;

$blue-light: #B3E6FA;
$blue-medium: #4DC6F4;
$blue-bold: #004E70;

$green-light: #B3E6FA;
$green-medium: #00ADEF;
$green-bold: #28874B;

$font-azul-real: #002541;
$font-cinza: #555555;
$font-cinza-azulado: #4B5667;
$font-texto-branco: #ffffff;
$font-error: #F44336;

$color-cards: #F9FBFF;
$color-alert-sucess: #F9FBFF; 
$color-alert-warning: #F9FBFF;
$color-alert-error: #F2C0C1;
$color-alert-snack: #3A4354;

$color-btn-hover: #266985;
$color-btn-active: #B3CAD4;

//Titulos/Header - Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
header, h1, h2, h3, h4, h5, h6, strong{
    font-family: 'Poppins', sans-serif;
}

//Textos - Open sans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
html{
    font-family: 'Open Sans', sans-serif;
    color: $font-azul-real;
}

body{
    margin: 0;
    padding: 0; //II-70 - INICIO/FIM
    /* PLV-4259 - INICIO */
    &.overflow-h{
        overflow: hidden;
    }
    /* PLV-4259 - FIM */
}

.hidden-xs{
    @media screen and (max-width: $desk){
        display: none !important;
    }
}

.hidden-lg{
    @media screen and (min-width: $desk){
        display: none !important;
    }
}

.modalLoaderErro{
    top: 80px;
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.15);
    transition: all 0.8s ease;
    z-index: 0; /* PLV-4896 */ 
    .content{
        width: 300px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .loader{
            border: 0px solid transparent;
            height: 100px;
            width: 100px;
            border-top: 3px solid #30c5ff;
            border-right: 3px solid transparent;
            border-radius: 50%;
            animation: girar 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
        @keyframes girar{
            to{
                transform: rotate(360deg);
            }
        }
    }
    &.off{
        opacity: 0;
        visibility: hidden;
    }
}

/* PLV-4254 - INICIO */
.btn-primary { 
    border: none;
    border-radius: 3px;
    padding: 4px 12px;
    font-size:1.0em;
    cursor: pointer;
    color: $color-btn-hover;
    border: 1px solid $color-btn-hover;
    background-color: #fff;
    outline: none;
    background-position: center;
    transition: all 0.5s;     
    svg{
        margin-right: 5px;
    }
    &:hover {
        background: $color-btn-hover;
        color:$font-texto-branco;
    }
    &:active {
        background-color: $color-btn-active;
        background-size: 100%;
    
        transition: all 0.2s;
    }
}

.input-field{
    position: relative;
    margin-right: 20px;
    input[type="text"]{
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $blue-bold;
        border-radius: 0;
        outline: none;
        height: 48px;
        width: 100%;
        font-size: 12px;
        padding: 0;
        box-shadow: none;
        box-sizing: content-box;
        transition: all .3s linear;
        margin: 0;
        min-width: 200px;

        &:focus{
            border-bottom: 1px solid $blue-medium;
            box-shadow: 0 1px 0 0 $blue-medium;
        }
    }
    label{
        color: $font-cinza-azulado;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        cursor: text;
        transition: all .2s ease-out;
        text-align: initial;
        transform: translateY(14px);
    }

    input[type="text"]:focus + label,
    input[type="text"]:not(:placeholder-shown) + label{
        transform: translateY(-14px) scale(0.8);
        transform-origin: 0 0;
    }

    &:last-of-type{
        margin-right: none;
    }
    &:after{
        content: attr(data-error);
        position: absolute;
        left: 0;
        bottom: -15px;
        font-size: 10px;
        color: $font-error;
        opacity: 0;
        transition: all 0.4s ease;
    }
    &.error{
        &:after{
            opacity: 1;
        }
    }
}

.select-wrapper{
    position: relative;
    margin-right: 20px;
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-padding-start: 5px;
        appearance: none;
        background-image: none;
        background: none;
        border: none;
        outline: none;
        border-bottom: 1px solid $blue-bold; 
        height: 48px;
        width: 100%;
        min-width: 200px;
        margin: 0;
        cursor: pointer;
        background: url(assets/icon_down_arrow.svg) no-repeat right;
        background-position-x: 98%;
        background-size: 18px;
        background-color: transparent;
        &:focus{
            border-bottom: 1px solid $blue-medium;
            box-shadow: 0 1px 0 0 $blue-medium;
        }
    }
    label{
        color: $font-cinza-azulado;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        cursor: text;
        transition: all .2s ease-out;
        text-align: initial;
        transform: translateY(-14px) scale(0.8);
        transform-origin: 0 0;
    }
    &:after{
        content: attr(data-error);
        position: absolute;
        left: 0;
        bottom: -15px;
        font-size: 10px;
        color: $font-error;
        opacity: 0;
        transition: all 0.4s ease;
    }
    &.error{
        &:after{
            opacity: 1;
        }
    }
}
/* PLV-4254 - FIM*/

/* PLV-4259 - INICIO */
.open-details{
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
        color: $blue-medium;
    }
}
/* PLV-4259 - FIM */
.novoSeguro {
    display: flex;
    //PLV-4534 Felipe INICIO
    margin-top: 180px;
    //PLV-4534 Felipe FIM 
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .novoSeguro-select {
      width: 300px;
  }

  .novoSeguro-select option {
    font-size: 15px;
}

  .btnPortoSuccess {
    position: relative;
    background-color: #28874b;
    color: #fff;
    height: 50px;
    border: 2px solid #28874b;
    outline: 2px #28874b;
    margin-top: 20px;
    background-size: 250% 100%;
    background-position: 98%;
    width: 100%;
    min-width: 200px;
    padding: 0 30px;
    transition: all 0.4s ease;
    cursor: pointer;
    &:hover {
      background: rgba(15, 87, 35, 0.8);
    }
    &:focus {
      background-color: #28874b;
      border: 2px solid #fff;
      outline: #28874b solid 2px;
    }
    &.download {
      color: #fff;
      padding: 10px;
      font-size: 15px;
      margin-left: 15px;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
  }